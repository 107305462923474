import React, { useState, useEffect } from 'react';
import { apibaseURl, showSuccessToast, showErrorToast,fileupload,filebasepath} from "../../../Function";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import axios from 'axios';
import serviceimg1 from '../../../admin/assest/img/avatars/9.png'

  const EditTestimonial = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [state, setState] = useState({
    title: '',
    descrip: '',
    rating:'',
    name:'',
    designation:'',
    status: '',
  });
  const [image, setImage] = useState('');
  // image upload api in ==================================> 
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(`${fileupload}addimage`, formData);
      console.log(response);
      if (response.status === 200 && response.data.status === true) {
        setImage(response.data.data.image);
      }
    } catch (error) {
      console.log("error in code")
    }
  };
  // single data show ===================>
  const handleFetchService = async () => {
    try {
      const response = await axios.get(`${apibaseURl}testimonial/${id}`);
      console.log(response)
      if (response.status === 200 && response.data.status === true) {
        setState({ ...response.data.data });
        setImage(response.data.data.image);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    handleFetchService();
  }, [id]);

  // updated data ===================================>
  const handleUpdatesubmit = async (e) => {
    e.preventDefault();
    const userdata = {
      title: state.title,
      image: image,
      descrip: state.descrip,
      rating:state.rating,
      name:state.name,
      designation:state.designation,
      status: state.status,
      id: id
    };
    try {
      const response = await axios.post(`${apibaseURl}edittestimonial`, userdata);
      if (response.status === 200 && response.data.status === true) {
        showSuccessToast(response.data.msg);
        navigate("/admin/admintestimonial");
      } else {
        showErrorToast(response.data.error);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  return (
    <>
           <div class="container-xxl flex-grow-1 container-p-y">
             <div class="row">
                <div class="col-md-12">
                  <div class="card mb-4">
                    <div className="data_acrd_wrapper">
                        <h5 className="card-header">Edit Testimonial</h5>
                        <Link to="/admin/admintestimonial" class="btn btn-primary primary_ya"><span className='class_plus'><IoMdArrowRoundBack/></span>Back</Link>
                    </div>
                    <div className='add_divider'> <hr/></div>
                   
                    <div class="card-body">
                      <form onSubmit={handleUpdatesubmit}>
                      <div className='mb-3'>
                        <label  className="form-label">Name</label>
                        <input
                            type="text"
                            class="form-control"
                            id="defaultFormControlInput" 
                            placeholder="Name" 
                            name="name"
                            value={state.name}
                            onChange={handleInputChange}
                           />
                       </div>

                      <div className='mb-3'>
                        <label  className="form-label">Testimonial Title</label>
                        <input
                          type="text"
                          class="form-control"
                          id="defaultFormControlInput" 
                           placeholder="Testimonial Title" 
                           name="title"
                           value={state.title}
                           onChange={handleInputChange}
                           />
                      </div>
               
                      <div className='mb-3'>
                        <label  className="form-label">Testimonial description</label>
                        <input
                          type="text"
                          class="form-control"
                          id="defaultFormControlInput" 
                           placeholder="Testimonial description"
                           name="descrip"
                           value={state.descrip}
                           onChange={handleInputChange} />
                      </div>


                      <div className='mb-3'>
                        <label  className="form-label">Testimonial Rating</label>
                        <input
                          type="text"
                          class="form-control"
                          id="defaultFormControlInput" 
                           placeholder="Testimonial Rating"
                           name="rating"
                           value={state.rating}
                           onChange={handleInputChange} />
                      </div>
                      

                      <div className='mb-3'>
                        <label  className="form-label">Testimonial  Designation</label>
                        <input
                          type="text"
                          class="form-control"
                          id="defaultFormControlInput" 
                           placeholder="Testimonial  Designation"
                           name="designation"
                           value={state.designation}
                           onChange={handleInputChange} />
                      </div>
                
                      <div className='mb-3'>
                        <label for="exampleFormControlSelect1" class="form-label">Testimonial Status</label>
                        <select class="form-select" id="exampleFormControlSelect1" 
                           name="status"
                           value={state.status}
                           onChange={handleInputChange}>
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </select>
                      </div>

                       <div class="mb-3">
                        <label for="formFileMultiple" class="form-label">Testimonial image</label><br/>
                        {image ? <img src={filebasepath + image} alt="Avatar" className=""  width="50px" /> 
                         :
                         ""
                        }
                        <input class="form-control"
                         type="file"
                         id="formFileMultiple"
                         onChange={handleFileUpload}
                         />
                         
                      </div>
                    
                                            
                      <div className="mb-3 text-center">
                        <button type="submit" className="btn btn-primary">
                          submit
                        </button>
                      </div>

                      </form>

                    </div>
                  </div>
                </div>
              </div>
         </div>
    </>
  )
}

export default EditTestimonial;

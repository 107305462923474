import React, { useState } from 'react';
import { apibaseURl, showSuccessToast, showErrorToast, fileupload,filebasepath} from "../../../Function";
import { Link, useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import axios from 'axios';

const Addimpact = () => {
  const [inputs, setInputs] = useState({
    title: '',
    counter: '',
    status: '',
  });
  const [image, setImage] = useState('');
  const navigate = useNavigate();


    // image upload api in ==================================> 
    const handleFileUpload = async (e) => {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("image", file);
  
      try {
        const response = await axios.post(`${fileupload}addimage`, formData);
        if (response.status === 200 && response.data.status === true) {
          setImage(response.data.data.image);
        }
      } catch (error) {
        console.log("error in code")
      }
    };
  
    // data insert in  api ==============>
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const servicedata = {
      title: inputs.title,
      image:image,
      counter: inputs.counter,
      status: inputs.status,
    };
    try {
      const response = await axios.post(`${apibaseURl}addimpact`, servicedata);
      if (response.status === 200) {
        if (response?.data?.status === true) {
          showSuccessToast(response?.data?.msg);
          navigate('/admin/impact')
        } else {
          if (response?.data?.error) {
            const errorMessages = getValidationErrors(response?.data?.error);
            showErrorToast(errorMessages);
          } else {
            showErrorToast('General Error', response?.data?.msg);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getValidationErrors = (errors) => {
    let errorMessages = '';
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        //errorMessages += `${key}: ${errors[key][0]}\n`; 
        errorMessages += `${errors[key].join(', ')}\n`
      }
    }
    return errorMessages;
  };
  return (
    <>
         <div class="container-xxl flex-grow-1 container-p-y">
             <div class="row">
                <div class="col-md-12">
                  <div class="card mb-4">
                    <div className="data_acrd_wrapper">
                        <h5 className="card-header">Add Impact</h5>
                        <Link to="/admin/impact" class="btn btn-primary primary_ya"><span className='class_plus'><IoMdArrowRoundBack/></span>Back</Link>
                    </div>
                    <div className='add_divider'> <hr/></div>
                   
                    <div class="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className='mb-3'>
                        <label  className="form-label">Impact Title</label>
                        <input
                          type="text"
                          class="form-control"
                          id="defaultFormControlInput" 
                           placeholder="Impact Title"
                           name="title"
                            value={inputs.title}
                            onChange={handleChange}
                            />
                      </div>
                      <div className='mb-3'>
                        <label  className="form-label">Impact Counter</label>
                        <input
                           type="text"
                           class="form-control"
                           id="defaultFormControlInput" 
                           placeholder="Impact Counter" 
                           name="counter"
                           value={inputs.counter}
                           onChange={handleChange}
                           />
                      </div>

                
                      <div className='mb-3'>
                        <label for="exampleFormControlSelect1" class="form-label">Impact Status</label>
                        <select class="form-select"
                         id="exampleFormControlSelect1"
                          aria-label="Default select example"
                           name="status"
                           value={inputs.status}
                           onChange={handleChange}
                          >
                          <option selected>select Status</option>
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </div>

                       <div class="mb-3">
                        <label for="formFileMultiple" class="form-label">Impact Icon</label>
                        <br/>
                        {image ? 
                        <img src={filebasepath + image} alt="Avatar" className=""  width="50px" /> 
                         : ""}
                        <input className="form-control" type="file" id="formFileMultiple" 
                         name="filename"
                         onChange={handleFileUpload} />
                      </div>

                      <div className="mb-3 text-center">
                        <button type="submit" className="btn btn-primary">
                          submit
                        </button>
                      </div>

                      </form>

                    </div>
                  </div>
                </div>
              </div>
         </div>
    </>
  )
}

export default Addimpact;

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { apibaseURl, filebasepath } from "../../Function";
import { GiRotaryPhone } from "react-icons/gi";
import { BsFillClockFill } from "react-icons/bs";
import { BiSolidTruck } from "react-icons/bi";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";

const ServiceSinglepage = ({ datasetting }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();
  const showEnquirydata = async () => {
    try {
      const responce = await axios.get(`${apibaseURl}service/slug/${slug}`);
      if (responce.status === 200) {
        if (responce?.data?.status === true) {
          setData(responce.data.data);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    showEnquirydata();
  }, []);

  return (
    <>
      {loading ? (
        <div
          className="loader_spinner_css_apply_for_single_service"
          style={{ height: "70vh" }}
        >
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["#313391", "#313391", "#313391", "#313391", "#313391"]}
          />
        </div>
      ) : (
        <>
          <section className="pey-6" container-xl="container-xl"></section>

          <section className="section_for_header_image">
            <div className="container">
              <div className="row">
                <div className="col-md-12 order-0">
                  {data.header_image ? (
                    <img
                      src={filebasepath + data.header_image}
                      alt="..."
                      width="100%"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-7 order-2 order-lg-1">
                  <div className="service_single_pageline">
                    <h1>{data.title}</h1>
                    {/* <div>{data.descrip}</div> */}
                    <div>
                      <span
                        dangerouslySetInnerHTML={{ __html: data.descrip }}
                      ></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 order-2 order-lg-2 mt_bar_top ">
                  <div className="bg_color_name">
                    <ul className="adress_list_show">
                      <li className="d-flex colgap">
                        <span className="span_icon">
                          <i
                            class="fa fa-map-marker icon_color_cs"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span className="d-flex fw_weight">
                          {datasetting.address}
                        </span>
                      </li>

                      {datasetting.admin_phone ? (
                        <li className="d-flex mt-2">
                          <Link
                            to={`tel:${datasetting.admin_phone}`}
                            className="d-flex color_name_address"
                          >
                            <span className="span_icon">
                              <i
                                class="fa fa-phone icon_color_cs"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span span className="fw_weight">
                              {datasetting.admin_phone}
                            </span>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}

                      {datasetting.admin_phone_two ? (
                        <li className="d-flex mt-2">
                          <Link
                            to={`tel:${datasetting.admin_phone_two}`}
                            className="d-flex color_name_address"
                          >
                            <span className="span_icon">
                              <i
                                class="fa fa-phone icon_color_cs"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span span className="fw_weight">
                              {datasetting.admin_phone_two}
                            </span>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}

                      {datasetting.fax_number_one ? (
                        <li className="d-flex mt-2">
                          <Link
                            to={`tel:${datasetting.fax_number_one}`}
                            className="d-flex color_name_address"
                          >
                            <span className="span_icon">
                              <GiRotaryPhone className="icon_color_cs" />
                            </span>
                            <span span className="fw_weight">
                              {datasetting.fax_number_one}
                            </span>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}

                      {datasetting.fax_number_two ? (
                        <li className="d-flex mt-2">
                          <Link
                            to={`tel:${datasetting.fax_number_two}`}
                            className="d-flex color_name_address"
                          >
                            <span className="span_icon">
                              <GiRotaryPhone className="icon_color_cs" />
                            </span>
                            <span span className="fw_weight">
                              {datasetting.fax_number_two}
                            </span>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}

                      {datasetting.admin_email ? (
                        <li className="d-flex mt-2">
                          <Link
                            to={`mailto:${datasetting.admin_email}`}
                            className="d-flex color_name_address"
                          >
                            <span className="span_icon">
                              <i
                                class="fa fa-map-marker icon_color_cs"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span span className="fw_weight">
                              {datasetting.admin_email}
                            </span>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}

                      {datasetting.admin_email_two ? (
                        <li className="d-flex mt-2">
                          <Link
                            to={`mailto:${datasetting.admin_email_two}`}
                            className="d-flex color_name_address"
                          >
                            <span className="span_icon">
                              <i
                                class="fa fa-map-marker icon_color_cs"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span span className="fw_weight">
                              {datasetting.admin_email_two}
                            </span>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}

                      <div className="social_media_icons_servise justify-content-end">
                        {datasetting.fb_icon ? (
                          <li className="">
                            <Link to={datasetting.fb_icon} target="_blank">
                              <i
                                class="fa fa-facebook-square"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </li>
                        ) : (
                          ""
                        )}

                        {datasetting.ig_icon ? (
                          <li className="">
                            <Link to={datasetting.ig_icon} target="_blank">
                              <i class="fa fa-instagram" aria-hidden="true"></i>
                            </Link>
                          </li>
                        ) : (
                          ""
                        )}

                        {datasetting.in_icon ? (
                          <li className="">
                            <Link to={datasetting.in_icon} target="_blank">
                              <i class="fa fa-instagram" aria-hidden="true"></i>
                            </Link>
                          </li>
                        ) : (
                          ""
                        )}

                        {datasetting.yt_icon ? (
                          <li className="">
                            <Link to={datasetting.yt_icon} target="_blank">
                              <i
                                class="fa fa-youtube-play"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </li>
                        ) : (
                          ""
                        )}
                      </div>
                    </ul>

                    <ul>
                      <li className="d-flex mb-2">
                        <span>
                          <BsFillClockFill className="icon_color_ccsd" />
                        </span>
                        <span className="d-flex address_left">
                          Hours of Operation
                        </span>
                      </li>
                      <li className="clock_time_hour fw_weight">
                        <span>Monday</span>
                        <span>9:00 am - 6:30 pm</span>
                      </li>
                      <li className="clock_time_hour fw_weight">
                        <span>Tuesday</span>
                        <span>9:00 am - 6:30 pm</span>
                      </li>
                      <li className="clock_time_hour fw_weight">
                        <span>Wednesday</span>
                        <span>9:00 am - 6:30 pm</span>
                      </li>
                      <li className="clock_time_hour fw_weight">
                        <span>Thursday</span>
                        <span>9:00 am - 6:30 pm</span>
                      </li>
                      <li className="clock_time_hour fw_weight">
                        <span>Friday</span>
                        <span>9:00 am - 6:30 pm</span>
                      </li>
                      <li className="clock_time_hour fw_weight">
                        <span>Saturday</span>
                        <span>10:00 am - 3:00 pm</span>
                      </li>
                      <li className="clock_time_hour fw_weight">
                        <span>Sunday</span>
                        <span>Closed</span>
                      </li>

                      <li className="d-flex mt-4">
                        <span>
                          <BiSolidTruck className="icon_color_cs" />
                        </span>
                        <span className="d-flex address_left">
                        Express pick up time  -2:00 pm
                        </span>
                      </li>

                      <li className="d-flex pt-1">
                        <span>
                          <BiSolidTruck className="icon_color_cs" />
                        </span>
                        <span className="d-flex address_left">
                         Ground pick up time -3.30 pm
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ServiceSinglepage;

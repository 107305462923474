import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
// import logoimg from '../../../front/assest/img/gallery/logo.png'
import { apibaseURl,filebasepath} from "../../../Function";
import axios from 'axios';

const Footer = ({datasetting}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [successms, setSuccessms] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState('');
  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

// subscribe email api Call ==========================>
  const handleSubmit = async (e) => {
    e.preventDefault();
    const subscribeemail = {
      email: email,
    };
    try {
      setIsLoading(true);
      const response = await axios.post(`${apibaseURl}addsubscribe`, subscribeemail);
      console.log(response)
      if (response.status === 200) {
        if (response?.data?.status === true) {
          setSuccessms(response?.data?.msg)
          setEmail('');
          setTimeout(()=>{
            setSuccessms("")
          },2000)
        } else {
          setError(response?.data?.error?.email?.[0] || '');
          setTimeout(() => {
            setError('');
          }, 5000); // Extract and set the email error message
        }
      }
    } catch (error) {
      console.log(error);
    }finally {
      setIsLoading(false); // Stop loading, regardless of the response
    }
  };

// setting show deata api email api Call ==========================>

  return (
    <>
  <section className=" footer-bg-color">
  <div className="container">
    <div className="row">
      <div className="col-lg-6">
        <h2 className="fw-bold text-white">Get an update every week</h2>
        <p className="text-300">
          We ensure that your product is delivered in the safest possible
          <br />
          manner, at the correct location, at the right time.
        </p>
      </div>
      <div className="col-lg-6">
        <h5 className="text-primary mb-3 text_font_weight_rap">SUBSCRIBE TO NEWSLETTER </h5>
        {successms ? 
           <div class="alert alert-success" role="alert">
              {successms} 
            </div>
       :""}
       
        <form className="row gx-2" onSubmit={handleSubmit}>
          <div className="col-md-8 col-12">
            <div className="input-group-icon">
              <label className="visually-hidden" htmlFor="inputEmailCta">
                Address
              </label>
              <input
                className="form-control input-box form-quriar-control "
                    id="inputEmail"
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
              />
               <span className="text-danger text_error_class">{error}</span>
            </div>
           
          </div>

          <div className="col-md-4 col-12">
            <button className="btn btn-danger bt_bar_set_type" type="submit" disabled={isLoading}>
              {isLoading ? 'Sending...' : 'Subscribe'}
            </button>
          </div>

        </form>
         
      </div>
    </div>
  </div>
  {/* end of .container*/}
</section>

    <section className="footer">
  <div className="container">
    <div className="row">
      <div className="col-12 col-sm-12 col-lg-6 mb-4 order-0 order-sm-0">
      {datasetting.site_logo ?
        <Link className="text-decoration-none" to="/" onClick={scrollToTop}>
          <img src={filebasepath + datasetting.site_logo} height={51} alt="" />
        </Link>
      :""
      }

        <p className="text-500 my-4">
        The most trusted Retail Shipping 
          <br />
          Agent in your neighbourhood.
        </p>
      </div>
      <div className="col-12 col-sm-12 col-lg-2 mb-3 order-2 order-sm-1">
        <h5 className="lh-lg fw-bold mb-4  font-sans-serif">Other links </h5>
        <ul className="list-unstyled mb-md-4 mb-lg-0">
          <li className="lh-lg">
            <Link className="text-500 text_colo_footer" to="about" onClick={scrollToTop}>
              About Us
            </Link>
          </li>
          <li className="lh-lg">
            <Link className="text-500 text_colo_footer" to="contact" onClick={scrollToTop}>
              Contact Us
            </Link>
          </li>
          <li className="lh-lg">
            <Link className="text-500 text_colo_footer" to="#" onClick={scrollToTop}>
            Review Us
            </Link>
          </li>
      
        </ul>
      </div>
      <div className="col-12 col-sm-12 col-lg-2 mb-3 order-3 order-sm-2">
        <h5 className="lh-lg fw-bold  mb-4 font-sans-serif">Supports</h5>
        <ul className="list-unstyled mb-md-4 mb-lg-0">
          <li className="lh-lg">
            <Link className="text-500 text_colo_footer" to="/privacypolicy" onClick={scrollToTop}>
              Privacy Policy{" "}
            </Link>
          </li>
          <li className="lh-lg">
            <Link className="text-500 text_colo_footer" to="termsconditions" onClick={scrollToTop}>
              Term &amp; Condition
            </Link>
          </li>
        </ul>
      </div>
      <div className="col-12 col-sm-12 col-lg-2 mb-3 order-3 order-sm-2">
        <h5 className="lh-lg fw-bold  mb-4 font-sans-serif"> Follow US</h5>

        <ul className="footer-icon-box">
        {datasetting.fb_icon && (
          <li>
            <Link to={datasetting.fb_icon} target='_blank'>
              <i className="fa fa-facebook"></i>
            </Link>
          </li>
        )}

        {datasetting.yt_icon && (
          <li>
            <Link to={datasetting.yt_icon} target='_blank'>
            <i class="fa fa-youtube-play" aria-hidden="true"></i>
            </Link>
          </li>
          )}

        {datasetting.ig_icon && (
          <li>
            <Link to={datasetting.ig_icon} target='_blank'>
            <i class="fa fa-instagram" aria-hidden="true"></i>
            </Link>
          </li>
          )}

          {datasetting.in_icon && (
          <li>
            <Link to={datasetting.in_icon} target='_blank'>
            <i class="fa fa-linkedin" aria-hidden="true"></i>
            </Link>
          </li>
          )}

        </ul>
      </div>
    </div>
  </div>
  {/* end of .container*/}
</section>

<section className="footer-bg-color footer_bottom">
  <div className="container">
    <div className="row justify-content-md-between justify-content-evenly py-2">
      <div className="col-12 col-sm-8 col-md-6 col-lg-auto text-center text-md-start">
        <p className="fs--1 my-2 fw-bold text-200">
          All rights Reserved ©  2023
        </p>
      </div>
      <div className="col-12 col-sm-8 col-md-6">
        <p className="fs--1 my-2 text-center text-md-end text-200">
          {" "}
          Design & Developed&nbsp;
 
           by&nbsp;
          <Link className="fw-bold text-primary" href="https://softechplanet.com/" target="_blank">
          Softech Planet
          </Link>
        </p>
      </div>
    </div>
  </div>
  {/* end of .container*/}
</section>

    </>
  )
}

export default Footer;



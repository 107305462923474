import React, { useState } from 'react';
import { apibaseURl, showSuccessToast, showErrorToast, fileupload ,filebasepath} from "../../../Function";
import { Link, useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DOMPurify from "dompurify";
import he from 'he';
const AddService = () => {
  const [inputs, setInputs] = useState({
    title: '',
    descrip: '',
    status: '',
    service_featured: '',
  });
  const [image, setImage] = useState('');
  const [headerimage, setHeaderimage] = useState('');
  const navigate = useNavigate();

  // image upload api in ==================================> 
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await axios.post(`${fileupload}addimage`, formData);
      if (response.status === 200 && response.data.status === true) {
        if(e.target.name == "serviceicon"){
          setImage(response.data.data.image);
        }else if(e.target.name == "serviceheaderimg"){
          setHeaderimage(response.data.data.image)
        }
        showSuccessToast(response?.data?.msg);
      }
    } catch (error) {
      console.log("error in code")
    }
  };

  // data insert in  api ==============>
  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const servicedata = {
      title: inputs.title,
      image: image,
      header_image:headerimage,
      descrip: inputs.descrip,
      status: inputs.status,
      service_featured: inputs.service_featured,
    };
    try {
      const response = await axios.post(`${apibaseURl}addservice`, servicedata);
      if (response.status === 200) {
        if (response?.data?.status === true) {
          const encodedDescription = response.data.data.descrip;
          const decodedDescription = he.decode(encodedDescription);

          function htmlToPlainText(decodedDescription) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = decodedDescription;
            return tempDiv.textContent || tempDiv.innerText || '';
          }
          const plainText = htmlToPlainText(decodedDescription);
          setInputs({ ...response.data.data.descrip, descrip: plainText });
          navigate('/admin/adminservice');
          showSuccessToast(response?.data?.msg);
        }
        else {
          if (response?.data?.error) {
             const errorMessages = getValidationErrors(response?.data?.error);
            showErrorToast(errorMessages);
          } else {
            showErrorToast('General Error', response?.data?.msg);
          }
        }
      }

    } catch (error) {
      console.log(error);
    }
  };

  const getValidationErrors = (errors) => {
    let errorMessages =[];
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        errorMessages.push(<div key={key}>{errors[key][0]}</div>);
      }else{
      }
    }
    
    const storeAllValidtionErr = (
          <>
            {errorMessages}
          </>
        );
        return storeAllValidtionErr;
   
  };

// image delte api call =================>
const handledeleteimage =()=>{
  setImage("")
}
const handledeleteimageheader =()=>{
  setHeaderimage("")
}
  return (
    <>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="data_acrd_wrapper">
                <h5 className="card-header">Add Service</h5>
                <Link to="/admin/adminservice" className="btn btn-primary primary_ya">
                  <span className="class_plus">
                    <IoMdArrowRoundBack />
                  </span>
                  Back
                </Link>
              </div>
              <div className="add_divider">
                {' '}
                <hr />
              </div>

              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">Service Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="defaultFormControlInput"
                      placeholder="Service Title"
                      name="title"
                      value={inputs.title}
                      onChange={handleChange}
                    />
                  </div>
      
                  <div className="col-md mb-3">
                    <small className="d-block"><b>SERVICE FEATURED</b></small>
                    <div className="form-check form-check-inline mt-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="service_featured"
                        id="inlineRadio1"
                        value="Yes"
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio1">
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="service_featured"
                        id="inlineRadio2"
                        value="No"
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="inlineRadio2">
                        No
                      </label>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleFormControlSelect1" className="form-label">
                      Service Status
                    </label>
                    <select
                      className="form-select"
                      id="exampleFormControlSelect1"
                      aria-label="Default select example"
                      name="status"
                      value={inputs.status}
                      onChange={handleChange}
                    >
                      <option>select Status</option>
                      <option value="active">active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="formFileMultiple" className="form-label">
                      Service Icon
                    </label>
                    <br/>
                    {image ?  
                       <div className='image_wapre_div_for_data_image'>
                          <img src={filebasepath + image} alt="Avatar" className=""  width="50px" /> 
                          <i class="fa fa-times bar_second_image_top" aria-hidden="true" onClick={handledeleteimage}></i>
                      </div>
                    :""
                    }
                    <input className="form-control mt-1"
                     type="file" id="formFileMultiple1" 
                      name="serviceicon"
                     onChange={handleFileUpload} />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="formFileMultiple" className="form-label">
                      Service Header Image
                    </label>
                    <br/>
                      {headerimage ?  
                       <div className='image_wapre_div_for_data_image'>
                          <img src={filebasepath + headerimage} alt="Avatar" className=""  width="200px" /> 
                          <i class="fa fa-times bar_second_image_top" aria-hidden="true" onClick={handledeleteimageheader}></i>
                      </div>
                     :""
                     }
                    <input 
                      className="form-control mt-1"
                      type="file"
                      id="formFileMultiple2" 
                      name="serviceheaderimg"
                     onChange={handleFileUpload} />

                     
                  </div>

                  <div className='mb-3'>
                     <label className="form-label">Service Description</label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={inputs.descrip}              
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            let clean = DOMPurify.sanitize(data);
                            console.log("hello" + data)
                            setInputs({ ...inputs, descrip: clean });
                          }}
                         
                          onReady={(editor) => {
                          
                            console.log('Editor is ready to use!', editor);
                          }}
                          onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                          }}
                        />
                     </div>

                  <div className="mb-3 text-center">
                    <button type="submit" className="btn btn-primary">
                      submit
                    </button>
                  </div>


                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddService;

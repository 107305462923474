import React,{useState,useEffect} from "react";
import logoimg from '../../../front/assest/img/gallery/logo.png'
import { Link, NavLink } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import {filebasepath} from "../../../Function";


const Header = ({datasetting}) => {

const [scroll, setScroll] = useState(false);
const [click,setClick]=useState(false);
const [data,setData]=useState(true);

const scrollToTop = () => {
  window.scrollTo(0, 0)
}
const handleNavLinkClick = () => {
  handleClick2();
  scrollToTop();
};
const handleClick =()=>setClick(!click)
const handleClick2 =()=>setData(!data)
 useEffect(() => {
   window.addEventListener("scroll", () => {
     setScroll(window.scrollY > 10);
   });
 }, []);


 
//  setting api call   ===========================>


 
  return (
    <>
      <nav
        className={`navbar navbar-expand-lg navbar-light fixed-top py-3 d-block back_bar ${scroll === true && "backdrop shadow-transition"} ${click === true && "shadow-transition"}`}>
        <div className="container">
        {datasetting.site_logo ?
          <Link className="navbar-brand m-0" to="/" onClick={scrollToTop}>
          <img src={filebasepath + datasetting.site_logo} alt="logo" height={45}/>
          </Link>
        :""
        } 


          <button
            className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
            onClick={handleClick} >
            <span className="navbar-toggler-icon"> </span>
          </button>
          <div className={`collapse navbar-collapse border-top border-lg-0 mt-4 mt-lg-0 justify-content-end  ${data ? "hide":""}`}   id="navbarSupportedContent">
            <ul className="navbar-nav  pt-2 pt-lg-0 font-base">
              <li className="nav-item px-2">
                <NavLink className="nav-link" aria-current="page"  to="/" onClick={handleNavLinkClick} smooth={true} duration={500}>Home</NavLink>
              </li>
              <li className="nav-item px-2">
                <NavLink className="nav-link" to="/service" onClick={handleNavLinkClick}>Our Services</NavLink>
              </li>
              <li className="nav-item px-2">
                <NavLink className="nav-link" to="/about" onClick={handleNavLinkClick}>About Us</NavLink>
              </li>
            </ul>
   
            <NavLink className="btn btn-primary order-1 order-lg-0 ms-lg-3" to="/contact" onClick={handleNavLinkClick}> Contact Us </NavLink>
          </div>
        </div>
      </nav>
      {/* scroll to top =======================================> */}
         <div>
          <ScrollToTop smooth />
        </div>
    </>
  );
};

export default Header;

import React, { useState,useEffect} from 'react'
import { Link,} from 'react-router-dom'
import { showErrorToast, showSuccessToast,apibaseURl,getValidationErrors, getLoggedInUserDetails} from '../../../Function';
import axios from 'axios';
import { ColorRing } from 'react-loader-spinner'

const Profile = () => {
const[showdata,setShowdata]=useState("profile")
const [loading, setLoading] = useState(true);
const [profile, setProfile] = useState({
  first_name: "",
  last_name:"",
  mobile:"",
  email:""
});
const handleClick =(currentbox="")=>{
    setShowdata(currentbox)
}
const id = getLoggedInUserDetails("id")
// deta fetch in react js ================================================================>
const handleFetchService = async () => {
  try {
    const response = await axios.get(`${apibaseURl}user/${5}`);
    if (response.status === 200 && response.data.status === true) {
      setProfile({ ...response.data.data});
      setLoading(false)
    }
  } catch (error) {
    console.error("An error occurred:", error);
  }
};

useEffect(() => {
  handleFetchService();
}, [id]);

//  update profile ===========================================================================>

const handleinputupdateChange = (e) => {
  const value = e.target.value;
  setProfile({
    ...profile,
    [e.target.name]: value
  });
};

const handleupdateSubmit = async (e) => {
  e.preventDefault();
  const userData = {
    first_name:profile.first_name,
    last_name:profile.last_name,
    mobile:profile.mobile,
    email:profile.email,
    id:id,
  };
  try{
   const responce = await axios.post(`${apibaseURl}profileupdate`, userData);
   if(responce?.status == 200 && responce?.data.status==true){
    setData(responce?.data.data)
    showSuccessToast(responce?.data.msg);
   }else{
      showErrorToast(responce?.data?.msg);
    }
  }catch(error){
   console.log("error in code")
  }

};
// change password api in react js=======================================================>

const [data, setData] = useState({
  id:getLoggedInUserDetails("id"),
  old_password: "",
  new_password: "",
  confirm_password:"",
});

const handleChange = (e) => {
  const value = e.target.value;
  setData({
    ...data,
    [e.target.name]: value
  });
};

const handleSubmit = async (e) => {
  e.preventDefault();
  try{
   const responce = await axios.post(`${apibaseURl}reset_user_password`, data);
   if(responce?.status == 200 && responce?.data.status==true){
    setData(responce?.data.data)
    showSuccessToast(responce?.data.msg);
   }else {
    if (responce?.data?.error) {
      const errorMessages = getValidationErrors(responce?.data?.error);
      showErrorToast(errorMessages);
    } else {
      showErrorToast(responce?.data?.msg);
    }
  }
  }catch(error){
   console.log("error in code")
  }

};


  return (
    <>
<div className="container-xxl flex-grow-1 container-p-y">
  <div className="row">
    <div className="col-md-12">
      <ul className="nav nav-pills flex-column flex-md-row mb-3">
        <li className="nav-item">
          <Link className={`nav-link ${showdata == "profile" && "active"}`}  onClick={()=>handleClick("profile")}>
            <i className="bx bx-user me-1" /> Profile
          </Link>
        </li>

        <li className="nav-item">
          <Link className={`nav-link ${showdata == "pass" && "active"}`}  onClick={()=>handleClick("pass")}>
            <i className="bx bx-bell me-1" /> Change Password
          </Link>
        </li>
      </ul>
{showdata === "profile" && 
<div className="card mb-4">
        <div className="card-body mt-4">
        {loading ?
             <div className='loader_spinner_css_apply'>
                          <ColorRing
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={['#313391', '#313391', '#313391', '#313391', '#313391']}
                        />
            </div>
             :
          <form onSubmit={handleupdateSubmit}>
            <div className="row">

              <div className="mb-3 col-md-6">
                <label htmlFor="lastName" className="form-label">first name</label>
                <input
                  className="form-control"
                  type="text"
                  name="first_name"
                  value={profile.first_name}
                  onChange={handleinputupdateChange}
                />
              </div>
              <div className="mb-3 col-md-6">
                <label htmlFor="lastName" className="form-label">last name</label>
                <input
                  className="form-control"
                  type="text"
                  name="last_name"
                  value={profile.last_name}
                  onChange={handleinputupdateChange}
                />
              </div>

              <div className="mb-3 col-md-6">
                <label htmlFor="lastName" className="form-label">Phone</label>
                <input
                  className="form-control"
                  type="number"
                  name="mobile"
                  value={profile.mobile}
                  onChange={handleinputupdateChange}
                />
              </div>

              <div className="mb-3 col-md-6">
                <label htmlFor="firstName" className="form-label">Email</label>
                <input
                  className="form-control"
                  type="email"
                  id="email"
                  name="email"
                  value={profile.email}
                  onChange={handleinputupdateChange}
                />
              </div>
            </div>
            <div className="mt-2">
              <button type="submit" className="btn btn-primary me-2">
                Save changes
              </button>
            </div>
          </form>
        }
        </div>
      </div>
}
    
{showdata === "pass" && 
<div className="card mb-4">
        <div className="card-body mt-4">
          <form onSubmit={handleSubmit}>
            <div className="row flex-direction-column justify-content-center align-items-center">
              <div className="mb-3 col-md-8">
                <label htmlFor="firstName" className="form-label">Old Password</label>
                <input
                  className="form-control"
                  type="Password"
                  id="old_password"
                  placeholder='Enter Old Password'
                  name="old_password"
                  value={data.old_password || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 col-md-8">
                <label htmlFor="lastName" className="form-label">New Password</label>
                <input
                  className="form-control"
                  type="Password"
                  id="new_password"
                  placeholder='Enter New Password'
                  name="new_password"
                  value={data.new_password || ""}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 col-md-8">
                <label htmlFor="lastName" className="form-label">Confirm Password</label>
                <input
                  className="form-control"
                  type="password"
                  id="confirm_password"
                  placeholder='Enter Confirm Password'
                  name="confirm_password"
                  value={data.confirm_password || ""}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mt-2 text-center">
              <button type="submit" className="btn btn-primary me-2">
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
}
    

    </div>
  </div>
</div>

    </>
  )
}

export default Profile

import React from 'react'
import HomeService from './home/HomeService';

const Service = ({servicedata,setFeaturedata }) => {
  return (
    <>
      <HomeService 
        isFeatured={false} 
        servicedata={servicedata}
        setFeaturedata={setFeaturedata }
        service_top_padding={"section_bar_for_service"}
         />
    </>
  )
}

export default Service;

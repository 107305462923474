import React, { Suspense, lazy, useState } from 'react'
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';

import { BallTriangle } from "react-loader-spinner";

const loaderStyles = {
  display: "flex",
  alignItems:"center",
  justifyContent:"center",
  height:"100vh"
};

function delayImport(delay) {
 return new Promise((resolve) => {
   setTimeout(() => {
     resolve(import('./AdminCss'));
   }, delay);
 });
}

const LazyComponent = lazy(() => delayImport(2000)); 

const AdminLayoute = () => {
const[click,setClick]=useState(false);
const handleClick=()=>setClick(!click);


const Loader=()=>{
  return(
    <div className="loader loader_center" style={loaderStyles}>
<BallTriangle
height={100}
width={100}
radius={5}
color="#313391"
ariaLabel="ball-triangle-loading"
wrapperClass={{}}
wrapperStyle=""
visible={true}

/>
  </div>
  )
  }


  return (
    <>
    <Suspense fallback={<Loader />}>
    <LazyComponent />
      <div className={`layout-wrapper layout-content-navbar ${click ==true && "layout-menu-expanded"}`}>
      <div className="layout-container">
         <Sidebar handleClick={handleClick}/>
      <div className="layout-page">
          <Header handleClick={handleClick}/>

      <div className="content-wrapper">
           <Outlet/>
      
          <Footer/>
        <div className="content-backdrop fade" />
      </div>
    </div>

  </div>

  <div className="layout-overlay layout-menu-toggle" />
</div>
</Suspense>
    </>
  )
}

export default AdminLayoute;

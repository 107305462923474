import React from 'react'

const Privacypolicy = () => {
  return (
    <>
    <section className="privecy_policy mt-5">
        <div className='container'>
        <h1 className='heading_sub_page_for_custome'>Privacy Policy</h1>
            <div className="privecy_policy_div">
           
                <span>Privacy Policy</span>
                <p className='mt-2'>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                  Dolore sit eveniet aspernatur sapiente quod delectus excepturi error quos
                   asperiores blanditiis similique earum perferendis aut ipsam dolorum voluptas 
                   dolores, sint voluptate!
                </p>
                <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                  Dolore sit eveniet aspernatur sapiente quod delectus excepturi error quos
                   asperiores blanditiis similique earum perferendis aut ipsam dolorum voluptas 
                   dolores, sint voluptate!
                </p>
                <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                  Dolore sit eveniet aspernatur sapiente quod delectus excepturi error quos
                   asperiores blanditiis similique earum perferendis aut ipsam dolorum voluptas 
                   dolores, sint voluptate!
                </p>
                <span>Security</span>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                  Dolore sit eveniet aspernatur sapiente quod delectus excepturi error quos
                   asperiores blanditiis similique earum perferendis aut ipsam dolorum voluptas 
                   dolores, sint voluptate!.{" "}
                </p>
            </div>
        </div>
    </section>

    </>
  )
}

export default Privacypolicy;

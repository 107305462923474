import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import { BiHomeCircle } from "react-icons/bi";
import { FiRadio } from "react-icons/fi";
import { TbUsersPlus } from "react-icons/tb";
import { MdOutlineMiscellaneousServices,MdWorkspacesOutline } from "react-icons/md";
import logoimg from '../../../admin/assest/img/logo/logo.png'
import { MdArrowBackIos,MdOutlineEmail } from "react-icons/md";
import { FiMap } from "react-icons/fi";
import { PiSlidersLight } from "react-icons/pi";
const Sidebar = ({handleClick}) => {
  return (
    <>
     <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
      <div className="app-brand demo">
        <Link to="#" className="app-brand-link">
          <span className="app-brand-text demo menu-text fw-bolder ms-2">
           <img src={logoimg} alt='img' width="100px" />
          </span>
        </Link>
        <Link to="#" className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none" >
          <MdArrowBackIos className="align-middle back_remove_icon" onClick={handleClick}/>
        </Link>
      </div>
      <div className="menu-inner-shadow" />

      <ul className="menu-inner py-1">

        <li className="menu-item">
          <NavLink to="dashboard" className="menu-link" onClick={handleClick}>
            <BiHomeCircle className="menu-icon tf-icons bx bx-home-circle" />
            <div data-i18n="Analytics">Dashboard</div>
          </NavLink>
        </li>


        <li className="menu-item">
          <NavLink to="sliderfor" className="menu-link" onClick={handleClick}>
            <PiSlidersLight className="menu-icon tf-icons bx bx-home-circle" />
            <div data-i18n="Analytics">Slider Manager</div>
          </NavLink>
        </li>
        
        <li className="menu-item">
          <NavLink to="adminservice" className="menu-link" onClick={handleClick}>
            <MdWorkspacesOutline className="menu-icon tf-icons bx bx-home-circle" />
            <div data-i18n="Analytics">Services</div>
          </NavLink>
        </li>




        {/* <li className="menu-item">
          <NavLink to="impact" className="menu-link" onClick={handleClick}>
            <MdOutlineMiscellaneousServices className="menu-icon tf-icons bx bx-home-circle" />
            <div data-i18n="Analytics">Impacts</div>
          </NavLink>
        </li> */}

{/* 
        <li className="menu-item">
          <NavLink to="admintestimonial" className="menu-link" onClick={handleClick}>
            <TbUsersPlus className="menu-icon tf-icons bx bx-home-circle" />
            <div data-i18n="Analytics">Testimonial</div>
          </NavLink>
        </li>

        <li className="menu-item">
          <NavLink to="adminmarketclient" className="menu-link" onClick={handleClick}>
            <FiMap className="menu-icon tf-icons bx bx-home-circle" />
            <div data-i18n="Analytics">Market & Clients</div>
          </NavLink>
        </li> */}

        <li className="menu-item">
          <NavLink to="adminenquery" className="menu-link" onClick={handleClick}>
            <FiRadio className="menu-icon tf-icons bx bx-home-circle" />
            <div data-i18n="Analytics">Enquirys</div>
          </NavLink>
        </li>


        <li className="menu-item">
          <NavLink to="subscribesemail" className="menu-link" onClick={handleClick}>
            <MdOutlineEmail className="menu-icon tf-icons bx bx-home-circle" />
            <div data-i18n="Analytics">Subscribers Email</div>
          </NavLink>
        </li>

        <li className="menu-item">
          <NavLink to="setting" className="menu-link" onClick={handleClick}>
            <i class="fa fa-cog menu-icon tf-icons" aria-hidden="true"></i>
            <div data-i18n="Analytics">Settings</div>
          </NavLink>
        </li>
    
      </ul>
    </aside>
    </>
  )
}

export default Sidebar;
